import { MASTER_ID, ADMIN_ID, OPERATOR_ID, READER_ID } from '@/utils/roles/role_ids';

export const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const filterAndSort = (items, filter, sort) => {
  return items
    .filter(filter)
    .sort(sort);
};

const isThereARole = (item) => {
  return typeof item.role === 'object' &&
    item.role &&
    typeof item.role.name === 'string' &&
    item.role.name.length;
};

const fixedRoles = [
  MASTER_ID,
  ADMIN_ID,
  OPERATOR_ID,
  READER_ID
];

export const sortUsersByRoles = (items) => {
  let orderedItems = [];

  const filterCustomRoles = (item) => isThereARole(item) && !fixedRoles.includes(item.role.id) &&
    item.hasOwnProperty('role');

  const filterNoRole = (item) =>
    !isThereARole(item);

  // append fixed roles items to ordered items
  for (const fixedRoleId of fixedRoles) {
    const filterFixedRoles = (item) =>
      isThereARole(item) &&
      item.role.id === fixedRoleId;
    const slice = filterAndSort(items, filterFixedRoles, sortByName);
    orderedItems = orderedItems.concat(slice);
  }

  const customRoles = filterAndSort(items, filterCustomRoles, sortByName);
  const noRoleUser = filterAndSort(items, filterNoRole, sortByName);

  orderedItems = orderedItems.concat(customRoles);
  orderedItems = orderedItems.concat(noRoleUser);

  return orderedItems;
};
