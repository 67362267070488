<template>
  <StReloadAndLoaderWrapper
    :request-status="requestStatus"
    class="user-role-table__wrapper"
    @reload="fetchUsersWithRoles"
  >
    <template v-slot:wrappedComponent>
      <v-card
        class="user-role-table"
        elevation="0"
      >
        <v-card-title
          class="user-role-table__search"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('Users.searchLabel')"
            single-line
            hide-details
          />
        </v-card-title>

        <v-data-table
          :headers="headers"
          :hide-default-footer="true"
          :items="usersWithRolesFiltered()"
          :options.sync="pagination"
          :search="search"
        >
          <template v-slot:item="props">
            <UserRoleRow
              :key="props.item.id"
              :user="props.item"
            />
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <div class="user-role-table__pagination-label">
            {{ paginationLabel }}
          </div>
          <v-pagination
            v-model="pagination.page"
            circle
            :length="pages"
            :total-visible="7"
          />
        </div>
      </v-card>
    </template>
  </StReloadAndLoaderWrapper>
</template>

<script>
import StReloadAndLoaderWrapper
  from '@stilingue/st-reload-and-loader-wrapper/StReloadAndLoaderWrapper';
import UserRoleRow from './UserRoleRow';
import { mapActions, mapState } from 'vuex';
import { sortUsersByRoles } from '@/utils/roles/user_role_table_sorter';
import { cloneDeep } from '@/plugins/lodash';

export default {
  name: 'UserRoleTable',
  components: {
    StReloadAndLoaderWrapper,
    UserRoleRow
  },
  props: {
    roleToFilter: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: this.$t('Users.column.name'),
          value: 'name'
        }, {
          text: this.$t('Users.column.email'),
          value: 'email'
        }, {
          text: this.$t('Users.column.role'),
          value: 'role.name',
          width: '250px'
        }
      ],
      pagination: {
        itemsPerPage: 10,
        page: 0,
        totalItems: 0
      }
    };
  },
  computed: {
    ...mapState('permissions', [
      'usersWithRoles',
      'requestStatus'
    ]),
    ...mapState('role', [
      'roles'
    ]),
    paginationLabel() {
      let rangeEnd = this.pagination.itemsPerPage * this.pagination.page;
      const rangeStart = rangeEnd - this.pagination.itemsPerPage + 1;
      const total = this.pagination.totalItems;
      rangeEnd = Math.min(rangeEnd, total);
      return this.$t('Users.pagination.label', {
        rangeStart,
        rangeEnd,
        total
      });
    },
    pages() {
      if (!this.pagination.itemsPerPage || !this.pagination.totalItems) {
        return 0;
      }
      return Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage);
    }
  },
  watch: {
    usersWithRoles() {
      this.$nextTick(this.updatePaginationTotalItems);
    },
    search() {
      this.$nextTick(this.updatePaginationTotalItems);
    }
  },
  async created() {
    !this.roles.length && await this.getRoles();
    await this.fetchUsersWithRoles();
  },
  beforeMount() {
    this.$nextTick(this.updatePaginationTotalItems);
  },
  methods: {
    ...mapActions('permissions', [
      'fetchUsersWithRoles'
    ]),
    ...mapActions('role', [
      'getRoles'
    ]),
    updatePaginationTotalItems() {
      this.pagination.totalItems = this.usersWithRolesFiltered().length;
    },
    usersWithRolesFiltered() {
      let usersWithRoles = cloneDeep(this.usersWithRoles);
      if (!usersWithRoles) return [];

      if (this.search) {
        usersWithRoles = usersWithRoles.filter(user => {
          const nameIncludes = user.name.toLowerCase().includes(this.search);
          const emailIncludes = user.email.toLowerCase().includes(this.search);
          const roleNameIncludes = user.role.name.toLowerCase().includes(this.search);
          return nameIncludes || emailIncludes || roleNameIncludes;
        });
      }

      if (!this.roleToFilter) {
        return sortUsersByRoles(usersWithRoles);
      }

      const usersWithRolesFiltered = usersWithRoles.filter(user => {
        return user.role && user.role.id && String(user.role.id) === String(this.roleToFilter);
      });

      return sortUsersByRoles(usersWithRolesFiltered);
    }
  }
};
</script>

<style lang="scss">
  .v-data-table__wrapper {
    overflow: hidden;
  }

  .user-role-table {
    width: 100%;
    min-width: 680px;

    .v-datatable__actions {
      display: none;
    }

    &__wrapper {
      min-height: 600px;
    }

    &__search {
      width: 387px;
      padding-left: 0;
    }

    &__header {
      padding: 0 24px;
      height: 36px;
    }

    &__column-title {
      font-size: 12px;
      font-weight: bold;
      color: #98999d;
    }

    &__roles-dropdown .v-input__slot::before {
      border-style: none !important;
    }

    &__pagination-label {
      display: inline-block;
      position: absolute;
      left: 23px;
      bottom: 9px;
    }
  }
</style>
