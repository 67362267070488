var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("StReloadAndLoaderWrapper", {
    staticClass: "user-role-table__wrapper",
    attrs: { "request-status": _vm.requestStatus },
    on: { reload: _vm.fetchUsersWithRoles },
    scopedSlots: _vm._u([
      {
        key: "wrappedComponent",
        fn: function() {
          return [
            _c(
              "v-card",
              { staticClass: "user-role-table", attrs: { elevation: "0" } },
              [
                _c(
                  "v-card-title",
                  { staticClass: "user-role-table__search" },
                  [
                    _c("v-text-field", {
                      attrs: {
                        "append-icon": "mdi-magnify",
                        label: _vm.$t("Users.searchLabel"),
                        "single-line": "",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    })
                  ],
                  1
                ),
                _c("v-data-table", {
                  attrs: {
                    headers: _vm.headers,
                    "hide-default-footer": true,
                    items: _vm.usersWithRolesFiltered(),
                    options: _vm.pagination,
                    search: _vm.search
                  },
                  on: {
                    "update:options": function($event) {
                      _vm.pagination = $event
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function(props) {
                        return [
                          _c("UserRoleRow", {
                            key: props.item.id,
                            attrs: { user: props.item }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "div",
                  { staticClass: "text-center pt-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "user-role-table__pagination-label" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.paginationLabel) +
                            "\n        "
                        )
                      ]
                    ),
                    _c("v-pagination", {
                      attrs: {
                        circle: "",
                        length: _vm.pages,
                        "total-visible": 7
                      },
                      model: {
                        value: _vm.pagination.page,
                        callback: function($$v) {
                          _vm.$set(_vm.pagination, "page", $$v)
                        },
                        expression: "pagination.page"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }