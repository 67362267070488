var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    [
      _c("td", [
        _c(
          "div",
          { staticClass: "user-role-row__avatar-name" },
          [
            _c("StUserAvatar", {
              attrs: {
                user: _vm.user,
                title: _vm.user.name,
                "user-image-path": "avatarUrl",
                size: "40"
              }
            }),
            _c(
              "span",
              { staticClass: "user-role-row__st-avatar text-truncate" },
              [_vm._v("\n        " + _vm._s(_vm.user.name) + "\n      ")]
            )
          ],
          1
        )
      ]),
      _c("td", [
        _c("div", { staticClass: "user-role-row__email" }, [
          _vm._v("\n      " + _vm._s(_vm.user.email) + "\n    ")
        ])
      ]),
      _c(
        "td",
        [
          _c(
            "v-row",
            {
              staticClass: "dropdown-container",
              attrs: { id: "dropdown-" + _vm.user.id }
            },
            [
              _vm.hasRole
                ? _c(
                    "v-col",
                    { staticClass: "pa-0", attrs: { cols: "9" } },
                    [
                      _vm.isMaster
                        ? _c(
                            "div",
                            { staticClass: "user-role-row__master-label" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.user.role.name) +
                                  "\n        "
                              )
                            ]
                          )
                        : _c("v-select", {
                            staticClass: "user-role-table__roles-dropdown",
                            attrs: {
                              items: _vm.companyRoles,
                              loading: _vm.alterRoleButtonLoading,
                              "single-line": true,
                              "append-icon": "mdi-chevron-down",
                              "item-text": "text",
                              "item-value": "id"
                            },
                            on: { change: _vm.changeUserRole },
                            model: {
                              value: _vm.currentRoleId,
                              callback: function($$v) {
                                _vm.currentRoleId = $$v
                              },
                              expression: "currentRoleId"
                            }
                          })
                    ],
                    1
                  )
                : _c(
                    "v-col",
                    {
                      staticClass: "user-role-row__invite-user invite-user",
                      attrs: { cols: "9" },
                      on: { click: _vm.confirmAddUserToCompany }
                    },
                    [
                      _c("StIcon", {
                        staticClass: "mr-1 invite-user__icon",
                        attrs: { "icon-name": "plus-circle-outline" }
                      })
                    ],
                    1
                  ),
              !_vm.isMaster && _vm.hasRole
                ? _c(
                    "v-row",
                    {
                      staticClass: "user-role-row__delete pr-2",
                      attrs: { justify: "end", align: "center" }
                    },
                    [
                      _c("StIcon", {
                        staticClass: "user-role-row__delete",
                        attrs: { "icon-name": "delete" },
                        nativeOn: {
                          click: function($event) {
                            _vm.showDeleteDialog = true
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("StDialog", {
        staticClass: "user-role-row__delete",
        attrs: {
          "cancel-button-text": _vm.$t("Users.cancel"),
          "confirm-button-loading": _vm.deleteRoleButtonLoading,
          "confirm-button-text": _vm.$t("Users.yes"),
          "hide-icon": true,
          show: _vm.showDeleteDialog
        },
        on: {
          confirm: _vm.deleteRole,
          cancel: function($event) {
            _vm.showDeleteDialog = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "user-role-row__dialog-text" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.removeMessage()) + "\n      "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("StDialog", {
        staticClass: "user-role-row__alter-role",
        attrs: {
          "cancel-button-text": _vm.$t("Users.cancel"),
          "confirm-button-loading": _vm.alterRoleButtonLoading,
          "confirm-button-text": _vm.$t("Users.yes"),
          "hide-icon": true,
          show: _vm.showAlterRoleDialog
        },
        on: { confirm: _vm.alterRole, cancel: _vm.cancelAlterRole },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "user-role-row__dialog-text" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.alterRoleMessage()) + "\n      "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("StDialog", {
        staticClass: "user-role-row__invite",
        attrs: {
          "icon-name": "account-plus",
          "icon-color": "var(--invite-user-color)",
          "cancel-button-text": _vm.$t("Users.cancel"),
          "confirm-button-loading": _vm.inviteUserButtonLoading,
          "confirm-button-text": _vm.$t("Users.yes"),
          show: _vm.showInviteUserToRoleDialog
        },
        on: {
          confirm: _vm.inviteUserToRole,
          cancel: function($event) {
            _vm.showInviteUserToRoleDialog = false
          }
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "user-role-row__dialog-text" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.inviteUserMessage()) + "\n      "
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }