<template>
  <tr>
    <!-- Avatar and Name -->
    <td>
      <div class="user-role-row__avatar-name">
        <StUserAvatar
          :user="user"
          :title="user.name"
          :user-image-path="'avatarUrl'"
          size="40"
        />
        <span class="user-role-row__st-avatar text-truncate">
          {{ user.name }}
        </span>
      </div>
    </td>

    <!-- Email -->
    <td>
      <div class="user-role-row__email">
        {{ user.email }}
      </div>
    </td>

    <!-- Selector of roles -->
    <td>
      <v-row
        :id="`dropdown-${user.id}`"
        class="dropdown-container"
      >
        <v-col
          v-if="hasRole"
          cols="9"
          class="pa-0"
        >
          <div
            v-if="isMaster"
            class="user-role-row__master-label"
          >
            {{ user.role.name }}
          </div>
          <v-select
            v-else
            v-model="currentRoleId"
            :items="companyRoles"
            :loading="alterRoleButtonLoading"
            :single-line="true"
            append-icon="mdi-chevron-down"
            class="user-role-table__roles-dropdown"
            item-text="text"
            item-value="id"
            @change="changeUserRole"
          />
        </v-col>
        <v-col
          v-else
          cols="9"
          class="user-role-row__invite-user invite-user"
          @click="confirmAddUserToCompany"
        >
          <StIcon
            icon-name="plus-circle-outline"
            class="mr-1 invite-user__icon"
          />
        </v-col>

        <v-row
          v-if="!isMaster && hasRole"
          justify="end"
          align="center"
          class="user-role-row__delete pr-2"
        >
          <StIcon
            icon-name="delete"
            class="user-role-row__delete"
            @click.native="showDeleteDialog = true"
          />
        </v-row>
      </v-row>
    </td>

    <!-- START DIALOGS -->
    <StDialog
      :cancel-button-text="$t('Users.cancel')"
      :confirm-button-loading="deleteRoleButtonLoading"
      :confirm-button-text="$t('Users.yes')"
      :hide-icon="true"
      :show="showDeleteDialog"
      class="user-role-row__delete"
      @confirm="deleteRole"
      @cancel="showDeleteDialog = false"
    >
      <template #body>
        <div class="user-role-row__dialog-text">
          {{ removeMessage() }}
        </div>
      </template>
    </StDialog>

    <StDialog
      :cancel-button-text="$t('Users.cancel')"
      :confirm-button-loading="alterRoleButtonLoading"
      :confirm-button-text="$t('Users.yes')"
      :hide-icon="true"
      :show="showAlterRoleDialog"
      class="user-role-row__alter-role"
      @confirm="alterRole"
      @cancel="cancelAlterRole"
    >
      <template #body>
        <div class="user-role-row__dialog-text">
          {{ alterRoleMessage() }}
        </div>
      </template>
    </StDialog>

    <StDialog
      class="user-role-row__invite"
      icon-name="account-plus"
      icon-color="var(--invite-user-color)"
      :cancel-button-text="$t('Users.cancel')"
      :confirm-button-loading="inviteUserButtonLoading"
      :confirm-button-text="$t('Users.yes')"
      :show="showInviteUserToRoleDialog"
      @confirm="inviteUserToRole"
      @cancel="showInviteUserToRoleDialog = false"
    >
      <template #body>
        <div class="user-role-row__dialog-text">
          {{ inviteUserMessage() }}
        </div>
      </template>
    </StDialog>
  </tr>
</template>

<script>
import StUserAvatar from '@stilingue/st-user-avatar/StUserAvatar';
import StIcon from '@stilingue/st-icon/StIcon';
import StDialog from '@stilingue/st-dialog/StDialog';
import { mapActions, mapState } from 'vuex';
import { READER_ID } from '@/utils/roles/role_ids';

export default {
  name: 'UserRoleRow',
  components: {
    StUserAvatar,
    StIcon,
    StDialog
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      alterRoleButtonLoading: false,
      deleteRoleButtonLoading: false,
      inviteUserButtonLoading: false,
      showAlterRoleDialog: false,
      showDeleteDialog: false,
      showInviteUserToRoleDialog: false,
      changingRole: this.user.role,
      currentRoleId: this.user.role.id
    };
  },
  computed: {
    ...mapState('role', [
      'roles'
    ]),
    companyRoles() {
      return this.roles.map(role => {
        return {
          text: role.name,
          id: role.id
        };
      });
    },
    hasRole() {
      return this.user.role &&
        typeof this.user.role.name === 'string' &&
        this.user.role.name.length;
    },
    isMaster() {
      return this.hasRole &&
        this.user.role.name.toLowerCase().trim() === 'master';
    }
  },
  methods: {
    ...mapActions('permissions', [
      'removeUserFromRole',
      'updateUserRole'
    ]),
    changeUserRole(newRoleId) {
      this.user.role = this.roles.find(role => role.id === newRoleId);
      this.showAlterRoleDialog = true;
    },
    confirmAddUserToCompany() {
      this.showInviteUserToRoleDialog = true;
    },
    removeMessage() {
      return this.hasRole
        ? this.$t('Users.confirmMessages.removeUserFromRole', {
          userRole: this.user.role.name,
          userName: this.user.name
        }) : '';
    },
    alterRoleMessage() {
      return this.$t('Users.confirmMessages.alterUserRole', {
        userName: this.user.name,
        changingRoleFromName: this.changingRole.name,
        roleName: this.user.role.name
      });
    },
    inviteUserMessage() {
      return this.$t('Users.confirmMessages.inviteUser', {
        userName: this.user.name
      });
    },
    deleteRole() {
      this.deleteRoleButtonLoading = true;
      this.removeUserFromRole({
        userId: this.user.id,
        changingRole: this.changingRole
      })
        .then(() => {
          this.user.role.name = '';
          this.showDeleteDialog = false;
        })
        .catch(() => {
          this.$noty.error(this.$t('Users.error.toDeleteRole'));
        })
        .finally(() => {
          this.deleteRoleButtonLoading = false;
        });
    },
    alterRole() {
      this.alterRoleButtonLoading = true;
      this.updateUserRole({
        user: this.user,
        changingRole: this.changingRole,
        roleId: this.user.role.id
      })
        .then(() => {
          this.showAlterRoleDialog = false;
          this.changingRole = this.user.role;
        })
        .catch(() => {
          this.$noty.error(this.$t('Users.error.toAlterRole'));
        }).finally(() => {
          this.alterRoleButtonLoading = false;
        });
    },
    inviteUserToRole() {
      this.inviteUserButtonLoading = true;
      // new users begin as reader
      this.updateUserRole({ user: this.user, roleId: READER_ID })
        .then(() => {
          this.showInviteUserToRoleDialog = false;
          this.changingRole = this.user.role;
        })
        .catch(() => {
          this.$noty.error(this.$t('Users.error.toInviteToRole'));
        })
        .finally(() => {
          this.inviteUserButtonLoading = false;
        });
    },
    cancelAlterRole() {
      this.showAlterRoleDialog = false;
      this.user.role = this.changingRole;
      this.currentRoleId = this.changingRole.id;
    }
  }
};
</script>

<style lang="scss">
  :root {
    --invite-user-color: #00aaa7;
  }

  .user-role-row__invite .v-icon {
    ::v-deep {
      font-size: 24px;
    }
  }

  .user-role-table__roles-dropdown {
    width: 100%;
  }

  .user-role-row {
    &__avatar-name {
      display: grid;
      grid-template-columns: 40px auto;
      align-items: center;
      min-width: 150px;
    }

    &__invite-user {
      color: var(--invite-user-color);
      cursor: pointer;
      padding-top: 22px;
      font-size: 16px;
      height: 68px;

      &:hover {
        opacity: 0.6;
      }

      i {
        color: var(--invite-user-color) !important;
        font-size: 15px;
      }
    }

    &__master-label {
      padding-top: 22px;
      font-size: 16px;
      height: 68px;
    }

    &__st-avatar {
      padding-left: 15px;
      font-size: 16px;
    }

    &__delete {
      cursor: pointer;
    }

    &__dialog-text {
      padding: 5px;
    }
  }

  .invite-user__icon {
    width: 100%;
    font-size: 24px !important;
    text-align: center;
  }

  /* truncates text in selector */
  .user-role-table {
    .v-select__selections {
      width: calc(100% - 106px);

      .v-select__selection {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
    }
  }

  .dropdown-container {
    max-width: 250px;
  }

  .theme--light.v-pagination .v-pagination__item:focus {
    outline: none;
  }
</style>
